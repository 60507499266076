import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[(_vm.login.auth)?_c('SideBar',{attrs:{"drawer":_vm.drawer},on:{"update:drawer":function($event){_vm.drawer=$event}}}):_vm._e(),(_vm.login.auth)?_c(VAppBar,{attrs:{"app":"","color":"teal darken-2","dense":"","dark":""}},[_c(VAppBarNavIcon,{staticClass:"white--text",on:{"click":function($event){_vm.drawer = true}}}),_c('div',{staticClass:"d-flex align-center"},[_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c(VSpacer),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":"","dark":""},on:{"click":_vm.logout}},on),[_c(VIcon,[_vm._v(" mdi-logout-variant ")])],1)]}}],null,false,2443255314)},[_c('span',{domProps:{"textContent":_vm._s('Cerrar sesión')}})])],1):_vm._e(),_c(VMain,[_c(VContainer,{attrs:{"fluid":""}},[_c('router-view')],1)],1),_c(VFooter,{attrs:{"app":"","padless":""}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6"}},[(_vm.login.auth)?_c('small',{staticClass:"pl-1 font-weight-bold",domProps:{"textContent":_vm._s(_vm.login.email)}}):_vm._e()]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"6"}},[_c('small',{staticClass:"pr-1",domProps:{"textContent":_vm._s(_vm.version)}})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }